import React from 'react'
import _ from 'lodash'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { inject, observer } from 'mobx-react'

import { ROUTE } from 'configs'

const AccountDropDown = ({ keycloakStore, keycloak, isOpen, userDetails = null }) => {
  const history = useHistory()
  const location = useLocation()

  const logout = () => {
    history.push('/')
    keycloak.logout()
  }

  const isActiveRoute = (route) => {
    return route === location.pathname
  }
  return (
    <Collapse isOpen={isOpen} navbar>
      <Nav className="mr-auto container-fluid" navbar>
        <UncontrolledDropdown nav inNavbar className="ml-auto">
          <DropdownToggle nav caret className="border-bottom border-primary d-flex align-items-center">
            {_.isEmpty(userDetails) ? (
              <>Loading ....</>
            ) : (
              <>
                {/* <div className="mr-2">
                  <img src="https://via.placeholder.com/40" alt={userDetails.name} class="rounded-circle" />
                </div> */}
                <div className="mr-3">
                  <p className="mb-0">Hi, {userDetails.name}</p>
                  <p className="mb-0 font-weight-bold">{userDetails.email}</p>
                </div>
              </>
            )}
          </DropdownToggle>
          <DropdownMenu className="w-100">
            <Link to={ROUTE.HOME} className="text-body">
              <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.HOME)}>
                <i className="material-icons mr-2">dashboard</i> Dashboard
              </DropdownItem>
            </Link>
            <Link to={ROUTE.PROFILE} className="text-body">
              <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.PROFILE)}>
                <i className="material-icons mr-2">person</i> Profile
              </DropdownItem>
            </Link>
            <Link to={ROUTE.LOGIN_HISTORY} className="text-body">
              <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.LOGIN_HISTORY)}>
                <i className="material-icons mr-2">history</i> Login History
              </DropdownItem>
            </Link>
            {keycloakStore.isPeplinkAdmin() && (
              <React.Fragment>
                <DropdownItem divider />
                <Link to={ROUTE.ADMIN_LOGIN_SUMMARY} className="text-body">
                  <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.ADMIN_LOGIN_SUMMARY)}>
                    <i className="material-icons mr-2">auto_graph</i> Active systems
                  </DropdownItem>
                </Link>
                <Link to={ROUTE.ADMIN_NEW_USER_SUMMARY} className="text-body">
                  <DropdownItem
                    className="d-flex align-items-center"
                    active={isActiveRoute(ROUTE.ADMIN_NEW_USER_SUMMARY)}
                  >
                    <i className="material-icons mr-2">bar_chart</i> User signup
                  </DropdownItem>
                </Link>
                <Link to={ROUTE.ADMIN_IC2_LOGIN_SETTINGS} className="text-body">
                  <DropdownItem
                    className="d-flex align-items-center"
                    active={isActiveRoute(ROUTE.ADMIN_IC2_LOGIN_SETTINGS)}
                  >
                    <i className="material-icons mr-2">settings</i> IC2 Login Settings
                  </DropdownItem>
                </Link>
                {/*<Link to={ROUTE.FAQs} className="text-body">
                <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.FAQs)}><i className="material-icons mr-2">dashboard_customize</i> Customize theme</DropdownItem>
              </Link>*/}
                <DropdownItem divider />
              </React.Fragment>
            )}
            <Link to={ROUTE.FAQs} className="text-body">
              <DropdownItem className="d-flex align-items-center" active={isActiveRoute(ROUTE.FAQs)}>
                <i className="material-icons mr-2">help</i> FAQs
              </DropdownItem>
            </Link>
            <DropdownItem className="d-flex align-items-center" onClick={() => logout()}>
              <i className="material-icons mr-2">exit_to_app</i>Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  )
}

export default inject('keycloakStore')(observer(AccountDropDown))
