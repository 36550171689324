import DeviceStore from './device'
import IC2SettingsStore from './ic2settings'
import KeycloakStore from './keycloak'
import MarkerIO from './markerIO'
import OrganizationStore from './organization'
import services from '../services'
import UserStore from './user'

const stores = {
  keycloakStore: new KeycloakStore(),
  userStore: new UserStore(services.userService),
  organizationStore: new OrganizationStore(services.organizationService),
  deviceStore: new DeviceStore(services.deviceService),
  ic2SettingsStore: new IC2SettingsStore(services.ic2SettingsService),
  markerIO: new MarkerIO('64115724e4ef0e18b0d53ed8', process.env.NODE_ENV),
}

export default stores
