
import React from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import { Input, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import Linkify from 'react-linkify';

import MainLayout from 'Common/MainLayout'
import Collapsible from 'Common/Collapsible'

const FAQS = {
  General: [
    {
      question: 'What is “Peplink ID”?',
      answer:
        'Peplink ID is your single account for logging in to Peplink systems. You can use it to login whenever you see this page:',
    },
    {
      question: 'Which systems support Peplink ID?',
      answer:
        'Currently Peplink ID can be used for eStore, Speedfusion, Speedfusion Cloud, Partner Pavilion, Training, SDWC, IotCloud and Forum. Going forwards, most if not all systems will use Peplink ID to login.',
    },
    {
      question: 'Why should I use Peplink ID?',
      answer:
        'Peplink ID allows you to use one account to log in and access different Peplink systems. No longer do you need to remember different accounts and passwords for different systems.',
    },
    {
      question: 'Can I share my Peplink ID with someone else?',
      answer: 'For security purposes, your Peplink ID should NOT be shared with anyone else.',
    }
  ],
  Support: [
    {
      question: 'What does “first time login” mean?',
      answer:
        'This means that you are using Peplink ID for the first time. We will need you to verify ownership of your email. You may be required to click on links in emails that we send to you.',
    },
    {
      question: 'I have not received any email from the system, what should I do?',
      answer:
        "You should receive an email in less than 1 minute, though sometimes it may take longer. If you have waited more than 5 minutes without receiving an email, check that the email didn't go to your Spam or Promotions folder. If you still have not received the email by then, retry the action or contact support.",
    },
    {
      question: 'I see "We hit a problem. Try signing in again through your application."',
      answer:
        'User actions have an expiration timer for additional security. Clicking on a stale link may result in the error above. Refresh the page and try again.',
    },
    {
      question: 'I used my Peplink ID account to log in to system X (e.g forum), and it says invalid password.',
      answer:
        'Peplink ID username/password can only be used with the Peplink ID login. As our systems often have multiple login types, please change the login type to “Peplink ID”. ',
    },
    {
      question: "I can't login using my username and password.",
      answer:
        'Make sure you are using your Peplink ID username and password to log in. In case you have forgotten your password, you may reset your Peplink ID password using the "Forget password" link.',
    },
    {
        question: "How can I reset my password?",
        answer:
          'Please follow the instructions described here: https://forum.peplink.com/t/how-to-create-a-peplink-id-and-reset-its-password/31201/2',
    },
    {
        question: 'I have not received email from the system. Our company uses Microsoft Outlook as the email server.',
        answer: 'If you could not receeive the verification email via Outlook, please follow the steps described here: https://forum.peplink.com/t/how-to-create-a-peplink-id-and-reset-its-password/31201/3',
    },
    {
        question: 'I would like to use an account on a Peplink system that is different from my main Peplink ID login. How do I do it without logging out of my existing systems?',
        answer: 'If your browser supports it, you may use different profiles on your browser. Please refer to this forum post (https://forum.peplink.com/t/using-different-accounts-for-peplink-id/38546) on how to set up multiple profiles. Alternatively, you may use a different browser or use incognito mode.',
    },
    {
        question: 'How can I create a new account?',
        answer: 'If you are getting the message that your account doesn’t exist, please follow the steps described below to create a Peplink ID account:https://forum.peplink.com/t/how-to-create-a-peplink-id-and-reset-its-password/31201',
    },
    {
	  question: 'What is my username in Peplink ID?',
	  answer: 'Your username is the email you used to register for Peplink ID.',
    },
    {
        question: 'I changed my phone. How do I reset my OTP?',
        answer: 'You may follow the instructions in this forum post - https://forum.peplink.com/t/Reset-your-Peplink-ID-OTP.',
      },
    {
      question: "I can't find solutions to my issues in this FAQ.",
      answer: 'Please create a ticket in https://ticket.peplink.com/ticket/new/public ',
    },
  ],
}

const FAQ = ({noUser=false}) => {
  const [search, setSearch] = React.useState('')
  const [selectedFilter, setSelectedFilter] = React.useState(null)
  return (
    <MainLayout title="FAQs" subtitle="Frequently Asked Questions" noUser={noUser}>
      <Row className="pt-3">
        <Col xs={12}>
          <h5 className="text-center mt-3 mb-5">
             Frequently Asked Questions
          </h5>
        </Col>
      </Row>
      <Input
        type="search"
        className="mb-1"
        placeholder="Search..."
        onChange={(e) => setSearch(e.target.value)}
      />
      <StyledNavFAQ tabs className="mb-4 mt-4">
        <NavItem>
          <NavLink
            className={classnames({ active: selectedFilter === null })}
            onClick={() => {
              setSelectedFilter(null)
            }}
          >
            All
          </NavLink>
        </NavItem>
        {Object.keys(FAQS).map((category) => (
          <NavItem key={category+Math.random()}>
            <NavLink
              className={classnames({ active: selectedFilter === category.toLocaleLowerCase() })}
              onClick={() => {
                setSelectedFilter(category.toLocaleLowerCase())
              }}
            >
              {category}
            </NavLink>
          </NavItem>
        ))}
      </StyledNavFAQ>
      {Object.keys(FAQS)
        .filter((faq) => (!selectedFilter ? true : faq.toLowerCase() === selectedFilter))
        .map((faqIndex, key) => (
          <StyledCollapsibleWrapper key={key}>
            {FAQS[faqIndex]
              .filter((faq, index) => {
                return (
                  faq.question.toLowerCase().includes(search.toLowerCase()) ||
                  faq.answer.toLowerCase().includes(search.toLowerCase())
                )
              })
              .map((faq, faqi) => {
                return (
                  <Collapsible
                    id={`faq-${faqIndex}-${faqi}`}
                    key={`faq-${faqIndex}-${faqi}`}
                    title={faq.question}
                    content={<Linkify>{faq.answer}</Linkify>}
                  />
                )
              })}
          </StyledCollapsibleWrapper>
        ))}
    </MainLayout>
  )
}

const StyledNavFAQ = styled(Nav)`
  li {
    a {
      font-weight: 600 !important;
      color: #333;
      cursor: pointer;
      padding: 0.5rem 1rem !important;
      border: none !important;
      &.active {
        border: none;
        border-bottom: 2px solid #ffb81c !important;
      }
    }
  }
`

const StyledCollapsibleWrapper = styled.div`
  a {
    &:hover {
      color:inherit;
      text-decoration:underline;
    }
  }
`

export default FAQ
