/* @flow */
import { decorate } from 'mobx'

import BaseStore from './base'

class IC2SettingsStore extends BaseStore {
  constructor(service) {
    super()
    this.service = service
  }

  uploadAttachment = async (attachment) => {
    this.CRUD.uploading = true
    const { data, errors } = await this.service.uploadAttachment(attachment)
    this.CRUD.uploading = false
    if (data && !errors.length) {
      this.CRUD.data = data
    } else {
      this.CRUD.errors = errors
    }
  }
}

export default decorate(IC2SettingsStore, {})
