/* @flow */
import React from 'react'

const ErrorsList = ({ errors }) => {
  return (
    <ul className="errors text-danger m-0 p-0">
      {errors.map((error, index) => {
        return (
          <li
            style={{ fontSize: 14, listStyle: 'none' }}
            key={index}
            dangerouslySetInnerHTML={{
              __html: error.message,
            }}
          />
        )
      })}
    </ul>
  )
}

export default ErrorsList
