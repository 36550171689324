import { Col, Modal, ModalBody } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

const StyledOl = styled.ol`
  margin: 0;
  padding: 5px 5px 0;
  li {
    margin: 0;
    margin-bottom: 15px;
    font-size: 0.75em;
    ul li {
      font-size: 1em;
    }
  }
`

const WebAuthnSetup = ({ open, toggle }) => {
  return (
    <Modal isOpen={open} size="md" backdrop="static">
      <ModalBody style={{ background: '#f3f1ed' }}>
        <Col xs={12}>
          <StyledOl>Logging out. WebAuthn will be setup on your next login.</StyledOl>
        </Col>
      </ModalBody>
    </Modal>
  )
}

export default inject('userStore')(observer(WebAuthnSetup))
