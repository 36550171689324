import { observable, decorate } from 'mobx'
import Keycloak from 'keycloak-js'


class KeycloakStore {
  keycloakConfig = {
    "realm":process.env.REACT_APP_KEYCLOAK_REALM,
    "clientId":process.env.REACT_APP_KEYCLOAK_RESOURCE,
    "resource":process.env.REACT_APP_KEYCLOAK_RESOURCE,
    "url": process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
    "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
    "public-client": process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT,
    "confidential-port": process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
    "enable-cors": process.env.REACT_APP_KEYCLOAK_ENABLE_CORS
  }
  
  keycloak = new Keycloak(this.keycloakConfig)
  
  setKeycloakCredentials = (keycloak, authenticated) => {
    localStorage.setItem('PEPLINK_ID_KEYCLOAK', keycloak)
    localStorage.setItem('PEPLINK_ID_IS_AUTHENTICATED', authenticated)
    this.keycloak = keycloak
    this.authenticated = authenticated
  }

  bearerToken = () => {
    return this.keycloak.token
  }

  isPeplinkAdmin = () => {
    try {
      return this.keycloak.tokenParsed.group_membership.indexOf("/Peplink Report") > -1;
    } catch (err) {
      console.error(err.message);
      return false;
    }
  }

}

export default decorate(KeycloakStore, {
  keycloak: observable,
  authenticated: observable,
})
