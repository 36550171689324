import { action, decorate, observable } from 'mobx'
import axios, { CancelToken } from 'axios'
import jwt_decode from 'jwt-decode'

import { jsonToQueryString } from '../utils/formatters'

export const removeStorage = (key) => {
  localStorage.removeItem(key)
}

export const forcedLogout = () => {
  removeStorage(this.sessionName)
  removeStorage(`${this.sessionName}_STORE_ID`)
  window.location.replace(`${window.location.origin}/login`)
}

let cancelRequestInstance = () => null

const CANCELLED_REQUEST = `message request cancelled`

export const cancelRequest = () => {
  cancelRequestInstance()
}

export const apiHasErrors = ({ data, errors }) => !!errors && errors.length > 0

class API {
  interceptorId = null
  isRenewingJWT = false
  sessionName = ''
  options = {}
  apiKey = null
  constructor(sessionName, options = {}) {
    this.sessionName = sessionName
    this.options = options
    this.apiKey = options.REACT_APP_API_KEY

    this.Instance = axios.create({
      /*eslint-disable no-undef*/
      baseURL: options.REACT_APP_API_SERVER,
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      cancelToken: new CancelToken((cancel) => {
        cancelRequestInstance = cancel
      }),
    })
  }
  responseParser(response) {
    const { data } = response
    return {
      data,
      errors: data.errorMessages || [],
    }
  }

  errorParser(e) {
    if (e.response) {
      const {
        data: { errorCode, errorMessages },
      } = e.response
      if (e.response.status === 401 /*|| e.response.status === 404*/) {
        window.location.reload()
      }

      if (e.response.status === 403 /*|| e.response.status === 404*/) {
        if (e.response.data) {
          localStorage.setItem('PAGE_ERROR', JSON.stringify(e.response.data))
        }
        window.location.replace(`/404?error`)
      }

      return {
        data: {},
        errorCode,
        errors: errorMessages || [{ message: 'Your request cannot be completed as of now' }],
      }
    }

    if (e.message && e.message === CANCELLED_REQUEST) {
      return { data: {} }
    }

    return {
      data: {},
      errors: [{ message: 'Your request cannot be completed as of now' }],
    }
  }

  getAuthHeader = () => {
    const { token } = this.getToken()
    return token ? { Authorization: `Bearer ${token}` } : null
  }

  getToken = () => {
    return localStorage.getItem(this.sessionName) && JSON.parse(localStorage.getItem(this.sessionName))
  }

  setToken = (data) => {
    const loginInfo = jwt_decode(data.id_token)
    localStorage.setItem(
      this.sessionName,
      JSON.stringify({
        token: data.id_token,
        role: loginInfo.auth || {},
      })
    )
  }

  removeToken = () => {
    localStorage.getItem(this.sessionName) && localStorage.removeItem(this.sessionName)
  }

  setHeaders(url = '') {
    // let isAuthenticated = false

    // eslint-disable-next-line no-prototype-builtins
    if (localStorage.hasOwnProperty(this.sessionName)) {
      // isAuthenticated = true
      this.Instance.defaults.headers = {
        ...this.Instance.defaults.headers,
        ...this.getAuthHeader(),
      }
    } else if (this.apiKey) {
      this.Instance.defaults.headers = {
        ...this.Instance.defaults.headers,
        ...{ Authorization: `Bearer ${this.apiKey}` },
      }
    }

    // if (isAuthenticated) {
    //   //refetch failed API logic
    //   this.Instance.interceptors.response.use(null, error => {
    //     if (error.config && error.response && error.response.status === 401) {
    //       const { clientKey, refreshToken, clientId } = this.getToken()
    //       this.isRenewingJWT = true
    //       const refreshTokenUrl = this.options.refreshTokenUrl || '/refresh-token'

    //       return this.Instance.post(refreshTokenUrl, {
    //         clientId: clientId,
    //         clientKey: clientKey,
    //         token: refreshToken,
    //       })
    //         .then(res => {
    //           const Bearer = `Bearer ${res.data.id_token}`
    //           const loginInfo = jwt_decode(res.data.id_token)
    //           localStorage.setItem(
    //             this.sessionName,
    //             JSON.stringify({ token: res.data.id_token, ...JSON.parse(loginInfo.sub), role: loginInfo.auth })
    //           )
    //           error.config.headers['Authorization'] = Bearer
    //           return axios.request(error.config)
    //         })
    //         .catch(err => {
    //           return Promise.reject(err)
    //         })
    //     } else if (error.config && error.response && error.response.status === 406) {
    //       localStorage.removeItem(this.sessionName)
    //       window.location.replace(`${window.location.origin}/login`)
    //     }

    //     return Promise.reject(error)
    //   })
    // }
  }

  async get(url, payload = {}, headers = {}) {
    this.setHeaders()
    return this.Instance.get(`${url}${jsonToQueryString(payload)}`, {
      ...headers,
    })
      .then(this.responseParser)
      .catch(this.errorParser)
  }

  async post(url, payload = {}, headers = {}) {
    this.setHeaders(url)
    return this.Instance.post(`${url}`, payload, headers).then(this.responseParser).catch(this.errorParser)
  }

  async put(url, payload = {}) {
    this.setHeaders()
    return this.Instance.put(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }

  async patch(url, payload = {}) {
    this.setHeaders()
    return this.Instance.patch(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }

  async delete(url, payload = {}) {
    this.setHeaders()
    return this.Instance.delete(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }
}

export default decorate(API, {
  Instance: observable,
  interceptorId: observable,
  isRenewingJWT: observable,
  responseParser: action.bound,
  errorParser: action.bound,
  setHeaders: action.bound,
  get: action,
  post: action,
  update: action,
  patch: action,
  del: action,
})
