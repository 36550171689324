import React from 'react'
import MainLayout from 'Common/MainLayout'
import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import moment from 'moment-timezone'
import useDeepCompareEffect from 'use-deep-compare-effect'

import ReactTable from 'Common/ReactTable'
import Loader from 'Common/Loader'

const LoginHistory = ({ userStore }) => {
  const [filters, setFilters] = React.useState({
    size: 10,
    pageIndex: 0,
    sortBy: [
      {
        id: 'timestamp',
        desc: true,
      },
    ],
  })
  const { Table: { data = [], loading, totalPages, totalRecordCount } = {} } = userStore

  const getTable = React.useCallback(async () => {
    let sort
    if (filters.sortBy && filters.sortBy.length) {
      const { id, desc } = filters.sortBy[0]
      sort = `${id},${desc ? 'desc' : 'asc'}`
    }
    userStore.fetchTable({
      page: filters.pageIndex,
      size: filters.size,
      search: filters.search,
      sort: sort,
      timezone: moment.tz.guess()
    })
  }, [filters, userStore])

  const fetchData = React.useCallback((filter) => {
    console.log(filter, 'fetchData')
    setFilters(filter)
  }, [])

  useDeepCompareEffect(() => {
    getTable()
  }, [filters, getTable])

  const columns = [
    {
      Header: 'Date/Time',
      accessor: 'eventTime',
      style: { width: '15%' },
    },
    {
      Header: 'Event',
      accessor: 'eventType',
      style: { width: '15%' },
    },
    {
      Header: 'IP',
      accessor: 'ipAddress',
      style: { width: '15%' },
    },
    {
      Header: 'Client',
      accessor: 'clientName',
      style: { width: '15%' },
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original.clientName || original.client,
    },
    // {
    //   Header: 'Details',
    //   accessor: 'details',
    //   style: { width: '15%' },
    //   className: 'text-center',
    //   Cell: ({
    //     cell: {
    //       row: { original },
    //     },
    //   }) => {
    //     const fields = ['username', 'auth_method', 'identity_provider']
    //     return (
    //       <div>{fields.filter((field) => original.details[field]).map((field) =>  `${field} = ${original.details && original.details[field]}`).join(', ')}</div>
    //     )
    //   },
    // },
  ]
  const tableData = React.useMemo(() => data, [data])
  return (
    <MainLayout hasHeader={false}>
      <Row className="pt-3 mt-3">
        <Col xs={12}>
          <h5 className="p-2 mb-5">Login History</h5>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ReactTable
            columns={columns}
            data={tableData}
            options={{
              filters,
              fetchData,
              totalElements: totalRecordCount,
              totalPages,
            }}
            search={false}
          />
          {loading && (
            <div className="loader-container">
              <Loader />
            </div>
          )}
        </Col>
      </Row>
    </MainLayout>
  )
}

export default inject('userStore')(observer(LoginHistory))
