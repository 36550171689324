/* @flow */
import BaseService from './base'

class OrganizationService extends BaseService {
  constructor(api) {
    super()
    this.api = api
    this.url = `/organizations/`
  }

  mergeOrganization = ({org, mergedOrganizations}) => {
    return this.api.post(`${this.url}/${org.id}/merged`, {
      ...org,
      mergedOrganizations
    })
  }
  
}

export default OrganizationService
