import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import { DateRangePicker } from '@peplink/peplink-ui/dist/components/Date'
import { format } from 'date-fns'
import { inject, observer } from 'mobx-react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MainLayout from 'Common/MainLayout'
import React from 'react'
import styled from 'styled-components'
import Theme from '@peplink/peplink-ui/dist/components/Theme'

const MainWrapper = styled.div`
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
`

const NewUserSummary = ({ userStore }) => {
  const dropdownFilters = [
    {
      label: 'Daily',
      subLabel: 'Day',
      value: 'DAILY',
    },
    {
      label: 'Weekly',
      subLabel: 'Week',
      value: 'WEEKLY',
    },
    {
      label: 'Monthly',
      subLabel: 'Month',
      value: 'MONTHLY',
    },
  ]
  const [selectedFilter, setSeletcedFilter] = React.useState(dropdownFilters[0].value)
  const [dateRange, setDateRange] = React.useState({
    from: null,
    to: null,
  })
  const [newUserSummary, setNewUserSummary] = React.useState([])
  const { CRUD: { loading } = {} } = userStore

  const subLabel = dropdownFilters.find((f) => f.value === selectedFilter).subLabel || dropdownFilters[0].subLabel

  const generateOptions = (newUserSummary) => {
    return {
      title: {
        text: `New signups per ${subLabel}`,
      },
      yAxis: {
        title: {
          text: 'Number of Signups',
        },
      },
      xAxis: {
        ...(selectedFilter !== 'DAILY'
          ? {
              categories: newUserSummary.map((d) => d[0]),
            }
          : { type: 'datetime' }),
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },
      series: [
        {
          name: 'Count',
          data: selectedFilter !== 'DAILY' ? newUserSummary.map((d) => d[1]) : newUserSummary,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    }
  }
  const getUser = async () => {
    const dateFormat = 'yyyy-MM-dd'
    await userStore
      .getNewUserSummary({
        filter: selectedFilter,
        ...(dateRange?.from && { from: format(dateRange.from, dateFormat) }),
        ...(dateRange?.to && { to: format(dateRange.to, dateFormat) }),
      })
      .then((newUserSummary) => {
        setNewUserSummary(newUserSummary)
      })
  }
  React.useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, dateRange])

  return (
    <MainWrapper>
      <MainLayout hasHeader={false}>
        <Row className="pt-3 mt-3">
          <Col xs={6}>
            <h5 className="p-2 mb-5">Signups per {subLabel}</h5>
          </Col>
          <Col xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
            <UncontrolledDropdown>
              <DropdownToggle caret style={{ lineHeight: '1.9', border: '1px solid #0000006b' }}>
                {dropdownFilters.find((f) => f.value === selectedFilter).label || 'Set a Filter'}
              </DropdownToggle>
              <DropdownMenu>
                {dropdownFilters.map((filter) => {
                  return (
                    <DropdownItem
                      active={filter.value === selectedFilter}
                      onClick={() => setSeletcedFilter(filter.value)}
                    >
                      {filter.label}
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Theme theme="PEPLINK_DEFAULT">
              <DateRangePicker className="dateRange" id="dateRange" onChange={setDateRange} showToggleCaret={false} />
            </Theme>
          </Col>
        </Row>

        <Row>
          {loading ? (
            <p className="text-center col-12">Loading Graph</p>
          ) : (
            <Col xs={12}>
              <HighchartsReact highcharts={Highcharts} options={generateOptions(newUserSummary)} />
            </Col>
          )}
        </Row>
      </MainLayout>
    </MainWrapper>
  )
}

export default inject('userStore')(observer(NewUserSummary))
