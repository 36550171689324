import moment from 'moment'

export const jsonToQueryString = (payload = {}) =>
  Object.keys(payload)
    .filter((key) => payload[key] !== null && payload[key] !== undefined)
    .map((key, index) => `${!index ? '?' : ''}${key}=${encodeURIComponent(payload[key])}`)
    .join('&')

export const getFormattedDateTimeString = (dateTimeString, defaultValue = 'N/A') => {
  const isValidDate = dateTimeString && moment(dateTimeString).isValid()
  if (!isValidDate) {
    return defaultValue
  }

  return moment(dateTimeString).format('DD MMM YYYY HH:mm')
}
