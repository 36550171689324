/* @flow */
import { decorate, observable } from 'mobx'

import BaseStore from './base'

class UserStore extends BaseStore {
  constructor(service) {
    super()
    this.service = service
  }

  App = {
    data: {},
    loading: false,
    errors: [],
  }

  OTPSetup = {
    data: {},
    loading: false,
    errors: [],
  }

  setDetailsFromKeycloak = (data) => {
    this.CRUD = { ...this.CRUD, data }
  }

  doLoadWithError = async (func) => {
    this.CRUD.errors = []
    this.CRUD.loading = true
    const { data, errors = [] } = await func()
    this.CRUD.loading = false
    return { data, errors }
  }

  doLoad = async (func) => {
    this.CRUD.loading = true
    const { data } = await func()
    this.CRUD.loading = false
    if (data) {
      return data
    }
    return []
  }

  onChangePassword = async (payload) => {
    const { data, errors } = await this.doLoadWithError(() => this.service.changePassword(payload))
    if (!errors.length) {
      return data
    } else {
      this.CRUD.errors = errors
    }
    return false
  }

  getAdminLoginSummary = async (payload) => {
    return this.doLoad(() => this.service.getAdminLoginSummary(payload))
  }

  getNewUserSummary = async (payload) => {
    return this.doLoad(() => this.service.getNewUserSummary(payload))
  }

  getUserAttributes = async () => {
    return this.doLoad(() => this.service.getUserAttributes())
  }

  getUserClients = async () => {
    return this.doLoad(() => this.service.getUserClients())
  }

  refreshCRUD = (data, errors) => {
    if (!errors.length) {
      this.CRUD.data = {
        ...this.CRUD.data,
        given_name: data.firstName,
        family_name: data.lastName,
        name: `${data.firstName} ${data.lastName}`,
        twoFactorEnabled: data.twoFactorEnabled,
      }
      return data
    } else {
      this.CRUD.errors = errors
    }
    return false
  }

  updateUser = async (id, payload) => {
    const { data, errors } = await this.doLoadWithError(() => this.service.updateUser(id, payload))
    return this.refreshCRUD(data, errors)
  }

  updateTwoFactor = async (payload) => {
    const { data, errors } = await this.doLoadWithError((payload) => this.service.updateTwoFactor(payload))
    return this.refreshCRUD(data, errors)
  }

  setToken = (token) => {
    this.service.setToken(token)
  }

  getRedirectUri = async (clientId) => {
    this.App.loading = true
    const { data, errors } = await this.service.getRedirectUri(clientId)
    this.App.loading = false
    if (!errors.length) {
      this.App.data = { ...data }
      return data
    } else {
      this.App.errors = errors
    }
    return false
  }

  uploadPhoto = async (id, payload) => {
    return this.doLoadWithError(() => this.service.uploadPhoto(id, payload))
  }

  removePhoto = async () => {
    return this.doLoadWithError(() => this.service.removePhoto())
  }

  getUser = async () => {
    const { data, errors } = await this.doLoadWithError(() => this.service.getUser())
    if (!errors.length) {
      this.CRUD.data = {
        ...this.CRUD.data,
        twoFactorEnabled: data.twoFactorEnabled,
        hasPassword: data.hasPassword,
      }
      return { data, errors }
    } else {
      this.CRUD.errors = errors
    }
    return false
  }

  getQR = async () => {
    return this.doLoadWithError(() => this.service.getQR())
  }

  requireActionWebAuthnPasswordless = async () => {
    return this.doLoadWithError(() => this.service.requireActionWebAuthnPasswordless())
  }

  getWebAuthnPasswordless = async () => {
    return this.doLoadWithError(() => this.service.getWebAuthnPasswordless())
  }

  registerWebAuthN = async (payload) => {
    return this.doLoadWithError(() => this.service.registerWebAuthN(payload))
  }

  deleteWebAuthN = async (credentialId) => {
    return this.doLoadWithError(() => this.service.deleteWebAuthN(credentialId))
  }

  submitQRSetup = async (payload) => {
    this.OTPSetup.loading = true
    const { data, errors } = await this.service.submitQRSetup(payload)
    this.OTPSetup.loading = false
    if (!errors.length) {
      return data
    } else {
      this.OTPSetup.errors = errors
    }
    return false
  }

  deleteProfile = async () => {
    return this.doLoadWithError(() => this.service.deleteProfile())
  }
}

export default decorate(UserStore, {
  App: observable,
  OTPSetup: observable,
})
