/* @flow */
import { decorate } from 'mobx'

import BaseStore from './base'

class OrganizationStore extends BaseStore {
  constructor(service) {
    super()
    this.service = service
  }

  getTable = async () => {
    this.Table.loading = true
    const { data, errors } = await this.service.fetchTable()
    this.Table.loading = true
    if (data && !errors.length) {
      this.Table.data = data
    }
  }

  mergeOrganization = async (payload) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.mergeOrganization(payload)
    this.CRUD.loading = false
    if (data && !errors.length) {
      return true
    } else {
      this.CRUD.errors = errors
      return false
    }
  }

}

export default decorate(OrganizationStore, {})
