import React from 'react'
import { inject, observer } from 'mobx-react'
import { useKeycloak } from '@react-keycloak/web'
import { Navbar, Container, Row, Col } from 'reactstrap'

import AccountDropDown from './AccountDropDown'
import Loader from 'Common/Loader'
import Logo from 'scss/assets/images/peplink-light.svg'
import { HOME_ICONS, paginate, generatePaginationArr } from 'utils/paginate'

const Home = ({ userStore }) => {
  const [keycloak, initialized] = useKeycloak()
  const [applications, setApplications] = React.useState([])
  const [userDetails, setUserDetails] = React.useState({})
  const [currentPage, setCurrentPage] = React.useState(0)

  const handleRedirectApp = async (app) => {
    const res = await userStore.getRedirectUri(app.id)
    if (res) {
      window.location = `${res.auth}`
    }
  }

  React.useEffect(() => {
    if (initialized && !!keycloak.token) {
      keycloak.loadUserInfo().then((userInfo) => {
        setUserDetails({ ...userInfo })
      })
      userStore.getUserClients().then((userClients) => {
        setApplications(userClients)
      })
    }
  }, [keycloak, initialized, userStore])
  const {
    CRUD: { loading },
    App,
  } = userStore
  const pages = generatePaginationArr(applications.length)
  const apps = applications.length && paginate(applications, HOME_ICONS, currentPage + 1)
  return (
    <div>
      <Navbar color="transparent" light expand="xs">
        <AccountDropDown keycloak={keycloak} userDetails={userDetails} />
      </Navbar>
      <Container style={{ marginTop: '5%' }}>
        <Row className="align-items-center">
          <Col xs={12} className="text-center">
            <img src={Logo} alt="Peplink" width={245} height={89} />
            <p className="mt-3 font-weight-bold">Let's get access to Peplink Application or Community</p>
          </Col>
        </Row>
        {/*  */}
        <Row className="mt-5">
          {loading ? (
            <p className="text-center col-12">Loading Apps...</p>
          ) : applications.length ? (
            <Col xs={12} md={8} className="d-flex flex-column justify-content-center offset-md-2">
              <div className="row d-flex align-items-start justify-content-lg-center justify-content-sm-start app-list">
                {apps.map((obj, i) => {
                  return (
                    <Col xs={6} sm={6} md={4} lg={3} key={i} className="my-3">
                      <a
                        href="#!"
                        className="text-dark d-flex flex-column align-items-center justify-content-center text-center"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault()
                          handleRedirectApp(obj)
                        }}
                      >
                        <div
                          className="bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style={{ width: 80, height: 80 }}
                        >
                          <img src={obj.iconImage ? obj.iconImage : '/images/r$__31.png'} alt={obj.name} width="40" />
                        </div>
                        <p className="font-weight-bold mt-2">{obj.name}</p>
                      </a>
                    </Col>
                  )
                })}
              </div>
              {pages.length > 1 && (
                <div className="pagination">
                  {pages.map((page) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <span key={page} onClick={() => setCurrentPage(page)} className={page === currentPage && 'active'}>
                      {' '}
                    </span>
                  ))}
                </div>
              )}
            </Col>
          ) : (
            <div className="d-flex justify-content-center w-100">No Apps Found.</div>
          )}
        </Row>
        <footer
          className="align-items-center d-flex flex-column justify-content-center w-100 mt-3"
          style={{ bottom: 0 }}
        >
          <div className="container other-links">
            <a className="text-body" href="https://www.peplink.com/service-terms-conditions/">
              Terms &amp; Conditions
            </a>{' '}
            |{' '}
            <a className="text-body" href="https://www.peplink.com/company/privacy/">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a className="text-body" href="https://www.peplink.com/company/contact-us/">
              Contact Us
            </a>
          </div>
          <div className="copyright m-2">© {new Date().getFullYear()} Peplink. All Rights Reserved.</div>
        </footer>
      </Container>

      {App.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </div>
  )
}

export default inject('keycloakStore', 'userStore')(observer(Home))
