import Api from 'utils/API'
import DeviceService from './device'
import IC2SettingsService from './ic2settings'
import OrganizationService from './organization'
import UserService from './user'

const options = {
  REACT_APP_API_SERVER: process.env.REACT_APP_API_BACKEND_SERVER,
}

const api = new Api('PEPLINK_ID', options, false)
const apiV2 = new Api(
  'PEPLINK_ID',
  {
    REACT_APP_API_SERVER: process.env.REACT_APP_API_BACKEND_SERVER_ROOT,
  },
  false
)

const userService = new UserService(api, apiV2)
const organizationService = new OrganizationService(api)
const deviceService = new DeviceService(api)
const ic2SettingsService = new IC2SettingsService(api)

export default {
  userService,
  organizationService,
  deviceService,
  ic2SettingsService,
}
