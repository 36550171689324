import { action, decorate, observable, toJS } from 'mobx'

const defaults = {
  Table: {
    originalData: {},
    data: [],
    loading: false,
    totalRecordCount: 0,
  },
  CRUD: {
    loading: false,
    data: {},
    errors: [],
  },
}

const dataReference = {
  loading: false,
  data: [],
  errors: [],
}

class BaseRepository {
  constructor(service) {
    this.service = service
  }

  Defaults = defaults
  Table = defaults.Table
  CRUD = defaults.CRUD
  List = {}

  fetchTable = async (payload) => {
    this.Table.loading = true
    const { data, errors } = await this.service.fetchTable(payload)
    if (data && !errors.length) {
      this.Table = {
        ...this.Table,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        totalPages: data.totalPages,
        loading: false,
        errors: [],
      }
      return true
    } else {
      this.Table.errors = errors
      this.Table.loading = false
    }
    return false
  }

  fetchList = async (key, id) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!toJS(this.List).hasOwnProperty(key))
      this.List[key] = {
        ...dataReference,
        data: [],
      }
    this.List[key].loading = true
    const { data, errors } = await this.service.fetchList(id)
    this.List[key].loading = false
    if (data && !errors.length) {
      this.List[key].loadingFailed = false
      this.List[key].data = data
    } else {
      this.List[key].loadingFailed = true
      this.List[key].errors = errors
    }
  }

  fetch = async (payload) => {
    const { data, errors } = await this.service.get(null, payload)
    return { data, errors }
  }

  getById = async (id = '') => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.getById(id)
    this.CRUD.loading = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      return true
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    return false
  }

  create = async (id, payload) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.create(id, payload)
    this.CRUD.loading = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      return true
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    return false
  }

  patch = async (id, payload) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.patch(id, payload)
    this.CRUD.loading = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      return true
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    return false
  }

  put = async (id, payload) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.put(id, payload)
    this.CRUD.loading = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      return true
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    return false
  }

  delete = async (id) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.delete(id)
    this.CRUD.loading = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      return true
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    return false
  }

  clearCrudData = () => {
    this.CRUD = defaults.CRUD
  }

  clearTableData = () => {
    this.Table = defaults.Table
  }

  clearErrorMessages = () => {
    this.CRUD.errors = []
  }

  reset() {
    this.Table = defaults.Table
    this.CRUD = defaults.CRUD
  }
}

export default decorate(BaseRepository, {
  Table: observable,
  CRUD: observable,
  List: observable,
  reset: action,
})
