import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

class NumberInput extends React.Component {
  handleKeyDown = e => {
    const { type } = this.props
    if (type !== 'currency') {
      switch (e.key) {
        case '.':
        case 'e':
          e.preventDefault()
          break
        default:
      }
    }
  }
  render() {
    const { value = '', ...rest } = this.props
    return <NumberInputStyled type="number" value={value} onKeyDown={this.handleKeyDown} {...rest} className="form-control"/>
  }
}

const NumberInputStyled = styled.input`
  text-align: center;
`

export default observer(NumberInput)
