/* @flow */
import BaseService from './base'

class UserService extends BaseService {
  constructor(api, apiV2) {
    super()
    this.api = api
    this.apiV2 = apiV2
    this.url = `/user`
    this.urlWebAuthN = `/webauthn`
  }

  getUserById = (id, params = {}) => {
    return this.post(`${this.url}/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${id}`, params)
  }

  changePassword = (params = {}) => {
    return this.api.put(`${this.url}/userUpdatePassword`, params)
  }

  updateUser = (id, payload) => {
    return this.api.put(`${this.url}`, payload)
  }

  updateTwoFactor = (payload) => {
    return this.api.put(`${this.url}/twoFactor?twoFactorEnabled=${payload}`)
  }

  getUserAttributes = () => {
    return this.api.get(`${this.url}/attributes`)
  }

  getUserClients = () => {
    return this.api.get(`${this.url}/clients`)
  }

  getUser = () => {
    return this.api.get(`${this.url}`)
  }

  getQR = () => {
    return this.api.get(`${this.url}/getQRCode`)
  }

  submitQRSetup = (payload) => {
    return this.api.post(`${this.url}/validateSetupQRCode`, payload)
  }

  getRedirectUri = (clientId) => {
    return this.api.get(`${this.url}/clients/${clientId}/application/auth/uri`)
  }

  removePhoto = () => {
    return this.api.delete(`/attachments/profile`)
  }

  uploadPhoto = (id, payload) => {
    return this.api.post(`/attachments/profile`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  fetchTable = (payload) => {
    return this.api.get(`${this.url}/events/login`, payload)
  }

  getAdminLoginSummary = (payload) => {
    return this.api.get(`/summary/login`, payload)
  }

  getNewUserSummary = (payload) => {
    return this.api.get(`/summary/newUser`, payload)
  }

  requireActionWebAuthnPasswordless = () => {
    return this.api.get(`${this.urlWebAuthN}/registrationFormDetails`)
  }

  getWebAuthnPasswordless = () => {
    return this.api.get(`${this.urlWebAuthN}/devices`)
  }

  registerWebAuthN = (payload) => {
    return this.api.post(`${this.urlWebAuthN}/registrationFormDetails`, payload)
  }

  deleteWebAuthN = (credentialId) => {
    return this.api.delete(`${this.urlWebAuthN}/devices/${credentialId}`)
  }

  deleteProfile = () => {
    return this.apiV2.delete(`/internal/v2/api`)
  }
}

export default UserService
