import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MainLayout from 'Common/MainLayout'
import React from 'react'
import ReactSelect from '@peplink/peplink-ui/dist/components/ReactSelect'
import Theme from '@peplink/peplink-ui/dist/components/Theme'

const LoginSummary = ({ userStore }) => {
  const { CRUD: { loading } = {} } = userStore
  const [loginSummary, setLoginSummary] = React.useState({})
  const [selectedFilter, setSeletcedFilter] = React.useState([])
  const [options, setOptions] = React.useState([])
  const [distinct, setDistinct] = React.useState(false)

  const generateOptions = (loginSummary) => {
    var date = new Date(loginSummary.pointStart)
    return {
      title: {
        text: 'Login per client per day',
      },
      yAxis: {
        title: {
          text: 'Number of Login',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
          pointInterval: 24 * 3600 * 1000, // one day
        },
      },
      series: loginSummary.series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    }
  }

  const getUser = async () => {
    await userStore
      .getAdminLoginSummary({
        distinct,
        ...(selectedFilter.length && {
          clientIds: selectedFilter.map((selected) => selected.value),
        }),
      })
      .then((loginSummary) => {
        setLoginSummary(loginSummary)
      })
  }

  React.useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [distinct, selectedFilter])

  React.useEffect(() => {
    if (loginSummary.selectedClients && !selectedFilter.length) {
      setSeletcedFilter([
        ...selectedFilter,
        ...Object.keys(loginSummary.selectedClients)
          .filter((key) => !selectedFilter.map((selected) => selected.value).includes(key))
          .map((fieldKey) => ({
            label: loginSummary.selectedClients[fieldKey],
            value: fieldKey,
          })),
      ])
    }

    if (loginSummary.availableClients && !options.length) {
      setOptions(
        Object.keys({ ...loginSummary.availableClients }).map((fieldKey) => ({
          label: loginSummary.availableClients[fieldKey],
          value: fieldKey,
        }))
      )
    }
    // eslint-disable-next-line
  }, [loginSummary])

  return (
    <div>
      <MainLayout hasHeader={false}>
        <Row className="pt-3 mt-3">
          <Col xs={12}>
            <h5 className="p-2">Login Summary</h5>
          </Col>
        </Row>
        <Row className="pt-3 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs={6}>
            <Theme theme="PEPLINK_DEFAULT">
              <ReactSelect
                label=""
                isClearable
                isMulti
                options={options}
                value={selectedFilter}
                onChange={(val) => {
                  setSeletcedFilter(val)
                }}
              />
            </Theme>
          </Col>
          <Col xs={6} className="text-right">
            <FormGroup check inline>
              <Input
                type="checkbox"
                name="showDistinct"
                id="showDistinct"
                onChange={(e) => {
                  setDistinct(e.target.checked)
                }}
                defaultChecked={distinct}
              />
              <Label check htmlFor="showDistinct" className="cursor-pointer">
                Show distinct users
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <p className="text-center col-12">Loading Graph</p>
          ) : (
            <Col xs={12}>
              <HighchartsReact highcharts={Highcharts} options={generateOptions(loginSummary)} />
            </Col>
          )}
        </Row>
      </MainLayout>
    </div>
  )
}

export default inject('userStore')(observer(LoginSummary))
