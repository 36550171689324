export const ROUTE = {
  HOME: '/',
  LOGIN: '/login',
  PROFILE: '/profile',
  FAQs: '/faq',
  LOGIN_HISTORY: '/login-history',
  ADMIN_LOGIN_SUMMARY: '/admin-login-summary',
  ADMIN_NEW_USER_SUMMARY: '/admin-new-user-summary',
  ADMIN_IC2_LOGIN_SETTINGS: '/admin-ic2-settings',
  SSO_CALLBACK: '/sso/callback',
  DELETE_PROFILE: '/profile/delete',
}

export const handleSSORedirect = () => {
  // eslint-disable-next-line max-len
  window.location.href = `${process.env.REACT_APP_PEPLINK_SSO_AUTH_URL}?client_id=${process.env.REACT_APP_PEPLINK_SSO_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_BASE_URL}`
}
