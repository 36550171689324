// import { detect } from 'detect-browser'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import DeviceDetector from 'device-detector-js'
import qs from 'query-string'
import React from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2'

import * as base64url from 'utils/base64url'
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { ROUTE } from 'configs'
import ErrorsList from 'Common/ErrorsList'
import MainLayout from 'Common/MainLayout'
import OTPSetup from 'Common/OTPSetup'
import ReactTooltip from 'react-tooltip'
import WebAuthnCredentials from 'Common/WebAuthnCredentials'
import WebAuthnSetup from 'Common/WebAuthnSetup'

const StyledSwitch = styled(CustomInput)`
  label.custom-control-label {
    line-height: 2;
  }
`
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-dark-border',
    cancelButton: 'btn btn-default',
  },
  buttonsStyling: false,
  allowOutsideClick: false,
})
const UserInfo = ({ userStore }) => {
  const [keycloak] = useKeycloak()
  const sources = ['peplink-ic2', 'rt']
  const { source, edit: isEdit } = qs.parse(window.location.search)
  const [isOpenOTPModal, setIsOpenOTPModal] = React.useState(false)
  const [isOpenWebAuthnModal, setIsOpenWebAuthnModal] = React.useState(false)
  const [edit, setEdit] = React.useState(isEdit || false)
  const [formValues, setFormValues] = React.useState({})
  const [serverErrors, setServerErrors] = React.useState([])
  const [enrollLoading, setEnrollLoading] = React.useState(false)

  const inputFile = React.useRef(null)
  const formRef = React.useRef(null)
  const switchRef = React.useRef(null)
  const switchSession = React.useRef(null)
  const {
    CRUD: { data: userData, errors, loading },
  } = userStore
  const windowClose = () => {
    // if (window.opener) {
    // }
    source && sources.includes(source) && window.close()
  }

  const DEFAULT_IMAGE = `https://ui-avatars.com/api/?size=128&background=ffb81c&name=${userData.name || 'Peplink'}`

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { firstName, lastName, twoFactorEnabled, webAuthEnabled, ...rest } = formValues
    const res = await userStore.updateUser(userData.sub, {
      firstName: firstName || userData.given_name,
      lastName: lastName || userData.family_name,
      twoFactorEnabled: typeof twoFactorEnabled !== 'undefined' ? twoFactorEnabled : userData.twoFactorEnabled,
      webAuthEnabled: typeof webAuthEnabled !== 'undefined' ? webAuthEnabled : userData.webAuthEnabled,
    })
    if (rest.currentPassword || rest.newPassword) {
      const passRes = await userStore.onChangePassword(rest)
      if (passRes) {
        formRef.current.reset()
        setEdit(false)
        swalWithBootstrapButtons
          .fire({
            icon: 'success',
            title: 'Success',
            text: 'Profile was successfully updated.',
            confirmButtonText: 'Close',
          })
          .then((res) => {
            if (res.value) {
              window.opener && window.opener.postMessage('saved', '*')
              windowClose()
            }
          })
        handleResetFormValues()
      } else {
        formRef.current.reset()
        swalWithBootstrapButtons
          .fire({
            icon: 'error',
            title: 'Failed',
            text: userStore.CRUD.errors.length > 0 ? userStore.CRUD.errors[0].message : 'Something went wrong.',
            confirmButtonText: 'Close',
          })
          .then((res) => {
            if (res.value) {
              window.opener && window.opener.postMessage('saved', '*')
              windowClose()
            }
          })
      }
    } else {
      if (res) {
        formRef.current.reset()
        setEdit(false)
        swalWithBootstrapButtons
          .fire({
            icon: 'success',
            title: 'Success',
            text: 'Profile was successfully updated.',
            confirmButtonText: 'Close',
          })
          .then((res) => {
            if (res.value) {
              window.opener && window.opener.postMessage('saved', '*')
              windowClose()
            }
          })
      }
    }
  }

  React.useEffect(() => {
    const getUser = async () => {
      if (userData.sub || userStore.CRUD.data.id) {
        await userStore.getUser()
      }
    }

    getUser()
    handleResetFormValues()

    return () => {
      userStore.CRUD.errors = []
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore, userData.sub])

  const reloadUser = () => {
    keycloak.loadUserInfo().then((userInfo) => {
      userStore.setDetailsFromKeycloak(userInfo)
    })
    userStore.getUser()
  }

  const handleAttachmentChange = async (e) => {
    setServerErrors([])

    const values = e.target
    let formAttachment = new FormData()
    inputFile.current.classList.remove('is-invalid')
    let isValid = true
    const reader = new FileReader()

    Object.keys(values.files).forEach((key) => {
      if (Math.round(values.files.item(key).size / 1024) > 3000) {
        setServerErrors([{ message: 'The maximum file size is 3mb' }])
        inputFile.current.classList.add('is-invalid')
        isValid = false
      }
      formAttachment.append('image', values.files[key])
    })

    if (isValid) {
      const { data, errors } = await userStore.uploadPhoto(userData.sub, formAttachment)

      if (data && !errors.length) {
        reloadUser()
        if (inputFile.current.files) reader.readAsDataURL(inputFile.current.files[0])
      } else {
        setServerErrors(errors)
      }
    }
  }

  const removePhoto = async (e) => {
    setServerErrors([])
    const { errors } = await userStore.removePhoto()
    if (errors.length) {
      setServerErrors(errors)
    } else {
      reloadUser()
    }
  }

  const handleResetFormValues = () => {
    setFormValues({
      ...formValues,
      firstName: userData.given_name,
      lastName: userData.family_name,
      twoFactorEnabled: userData.twoFactorEnabled,
      webAuthEnabled: userData.webAuthEnabled,
      currentPassword: '',
      newPassword: '',
      confirmation: '',
      logoutActiveSession: true,
    })
  }

  const handleCancel = () => {
    userStore.CRUD.errors = []
    setEdit(false)
    handleResetFormValues()
  }

  const getPic = () => {
    return userData.picture
      ? userData.picture.includes('https') || userData.picture.includes('http')
        ? userData.picture
        : `${process.env.REACT_APP_BASE_URL}${userData.picture}`
      : DEFAULT_IMAGE
  }

  function registerSecurityKey({
    challenge,
    userId: userid,
    userName: username,
    signatureAlgorithms,
    rpEntityName,
    rpId,
    attestationConveyancePreference,
    authenticatorAttachment,
    requireResidentKey,
    userVerificationRequirement,
    createTimeout,
    excludeCredentialIds,
  }) {
    // const browser = detect()
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(window.navigator.userAgent)
    let pubKeyCredParams = getPubKeyCredParams(signatureAlgorithms)

    let rp = { name: rpEntityName }

    let publicKey = {
      challenge: base64url.decode(challenge, { loose: true }),
      rp: rp,
      user: {
        id: base64url.decode(userid, { loose: true }),
        name: username,
        displayName: username,
      },
      pubKeyCredParams: pubKeyCredParams,
    }

    publicKey.rp.id = window.location.origin.includes('localhost') ? 'localhost' : rpId

    if (attestationConveyancePreference !== 'not specified') publicKey.attestation = attestationConveyancePreference

    let authenticatorSelection = {}
    let isAuthenticatorSelectionSpecified = false

    if (authenticatorAttachment !== 'not specified') {
      authenticatorSelection.authenticatorAttachment = authenticatorAttachment
      isAuthenticatorSelectionSpecified = true
    }

    if (requireResidentKey !== 'not specified') {
      if (requireResidentKey === 'Yes') authenticatorSelection.requireResidentKey = true
      else authenticatorSelection.requireResidentKey = false
      isAuthenticatorSelectionSpecified = true
    }

    if (userVerificationRequirement !== 'not specified') {
      authenticatorSelection.userVerification = userVerificationRequirement
      isAuthenticatorSelectionSpecified = true
    }

    if (isAuthenticatorSelectionSpecified) publicKey.authenticatorSelection = authenticatorSelection

    if (createTimeout !== 0) publicKey.timeout = createTimeout * 1000

    let excludeCredentials = getExcludeCredentials(excludeCredentialIds)
    if (excludeCredentials.length > 0) publicKey.excludeCredentials = excludeCredentials
    navigator.credentials.get()
    navigator.credentials
      .create({ publicKey })
      .then(async (result) => {
        window.result = result
        let clientDataJSON = result.response.clientDataJSON
        let attestationObject = result.response.attestationObject
        let publicKeyCredentialId = result.rawId

        const payload = {
          clientDataJSON: base64url.encode(new Uint8Array(clientDataJSON), { pad: false }),
          attestationObject: base64url.encode(new Uint8Array(attestationObject), { pad: false }),
          publicKeyCredentialId: base64url.encode(new Uint8Array(publicKeyCredentialId), { pad: false }),
        }

        // payload.authenticatorLabel = browser.name + ' version:' + browser.version + ' os:' + browser.os
        const authenticatorLabel = device?.device?.model || `${device?.os?.name} ${device?.os?.version}`
        let labelResult = window.prompt("Please input your registered authenticator's label", authenticatorLabel)

        if (labelResult === null) labelResult = authenticatorLabel

        payload.authenticatorLabel = labelResult
        const resgisterResponse = await userStore.registerWebAuthN(payload)
        setEnrollLoading(false)
        if (!!resgisterResponse.errors.length) {
          swalWithBootstrapButtons
            .fire({
              icon: 'error',
              title: 'Enroll Device Failed',
              text: resgisterResponse.errors.length > 0 ? resgisterResponse.errors[0].message : 'Something went wrong.',
              confirmButtonText: 'Close',
            })
            .then((res) => {
              if (res.value) {
                window.opener && window.opener.postMessage('saved', '*')
                windowClose()
              }
            })
        } else {
          swalWithBootstrapButtons
            .fire({
              icon: 'success',
              title: 'Enroll Device Success',
              text: 'You have successfully enrolled a device.',
              confirmButtonText: 'Close',
            })
            .then((res) => {
              if (res.value) {
                window.opener && window.opener.postMessage('saved', '*')
                windowClose()
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            })
        }
      })
      .catch(function (err) {
        setEnrollLoading(false)
        swalWithBootstrapButtons
          .fire({
            title: 'Enroll Device Cancelled',
            text: `You have cancelled enrolling a device`,
            confirmButtonText: 'Close',
          })
          .then((res) => {
            if (res.value) {
              window.opener && window.opener.postMessage('saved', '*')
              windowClose()
            }
          })
        console.log('WebAuthN Error: ', err)
      })
  }

  function getPubKeyCredParams(signatureAlgorithms) {
    let pubKeyCredParams = []
    if (!signatureAlgorithms.length) {
      pubKeyCredParams.push({ type: 'public-key', alg: -7 })
      return pubKeyCredParams
    }

    for (const alg of signatureAlgorithms) {
      pubKeyCredParams.push({
        type: 'public-key',
        alg: alg,
      })
    }
    return pubKeyCredParams
  }

  function getExcludeCredentials(excludeCredentialIds) {
    let excludeCredentials = []
    if (excludeCredentialIds === '') return excludeCredentials

    let excludeCredentialIdsList = excludeCredentialIds.split(',')

    for (let i = 0; i < excludeCredentialIdsList.length; i++) {
      excludeCredentials.push({
        type: 'public-key',
        id: base64url.decode(excludeCredentialIdsList[i], { loose: true }),
      })
    }
    return excludeCredentials
  }
  const handleEnrollWebAuthN = () => {
    if (!userData.webAuthEnabled) {
      setEnrollLoading(true)
      userStore.requireActionWebAuthnPasswordless().then(({ data }) => {
        registerSecurityKey(data)
      })
    }
  }

  return (
    <MainLayout hasHeader={false}>
      <Row className="pt-3 mt-3">
        <Col xs={12}>
          <h5 className="p-2 mb-5 text-center">Profile</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={3} className="text-center position-relative">
          <img src={getPic()} className="rounded-circle mb-3" alt={userData.name} height={128} width={128} />
          <input
            type="file"
            accept="image/*"
            id="photo"
            className="d-none"
            ref={inputFile}
            onChange={handleAttachmentChange}
          />
          {getPic() && getPic() !== DEFAULT_IMAGE ? (
            <Button
              className="rounded-circle btn-photo btn-photo-remove"
              size="sm"
              color="default"
              onClick={() => {
                removePhoto()
              }}
            >
              <i className="material-icons align-middle">delete</i>
            </Button>
          ) : (
            ''
          )}
          <Button
            className="rounded-circle btn-photo"
            size="sm"
            color="default"
            onClick={() => {
              if (inputFile) inputFile.current.click()
            }}
          >
            <i className="material-icons align-middle">photo_camera</i>
          </Button>
          <ErrorsList errors={serverErrors} />
          <p className="text-primary text-uppercase font-weight-bold mb-2">{userData.name}</p>
          <p>{userData.email}</p>
        </Col>
        <Col xs={12} sm={9}>
          <Form onSubmit={handleSubmit} innerRef={formRef}>
            <Row className="mb-3">
              <Col xs={12}>
                <label className="text-primary  text-uppercase font-weight-bold">Account Information</label>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label className="font-weight-bold">
                    First name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    required
                    defaultValue={userData.given_name}
                    value={formValues.firstName}
                    disabled={!edit}
                    onChange={(e) => setFormValues({ ...formValues, firstName: e.target.value })}
                    bsSize="lg"
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label className="font-weight-bold">
                    Last name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    required
                    defaultValue={userData.family_name}
                    value={formValues.lastName}
                    disabled={!edit}
                    onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
                    bsSize="lg"
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <label className="text-primary  text-uppercase font-weight-bold">Security</label>
              </Col>
              <Col
                xs={12}
                sm={6}
                className="position-relative"
                data-type="warning"
                data-place="bottom"
                data-border="true"
                data-multiline="true"
                // eslint-disable-next-line max-len
                data-tip="Enabling two-factor authentication will add an additional layer of security to your Peplink portals. <br />This feature will require an additional code to your password to sign in."
                data-for="twoFactorEnabled"
              >
                <StyledSwitch
                  type="switch"
                  id="twoFactorEnabled"
                  name="twoFactorEnabled"
                  label="Enable Two Factor Authentication"
                  disabled={!edit}
                  innerRef={switchRef}
                  defaultChecked={userData.twoFactorEnabled}
                  checked={formValues.twoFactorEnabled}
                  onClick={(e) => {
                    setFormValues({ ...formValues, twoFactorEnabled: switchRef.current.checked })
                    if (!userData.twoFactorEnabled) setIsOpenOTPModal(!isOpenOTPModal)
                  }}
                />
                {/* <StyledSwitch
                  type="switch"
                  id="webAuthnEnabled"
                  name="webAuthnEnabled"
                  label="Enable Web Authentication"
                  disabled={!edit}
                  innerRef={switchRef}
                  defaultChecked={userData.webAuthEnabled}
                  checked={formValues.webAuthEnabled}
                  onClick={(e) => {
                    setFormValues({ ...formValues, webAuthEnabled: switchRef.current.checked })
                    if (!userData.webAuthEnabled) {
                      setIsOpenWebAuthnModal(!isOpenWebAuthnModal)
                      userStore.requireActionWebAuthnPasswordless().then(({ data }) => {
                        console.log(data)
                        setTimeout(function () {
                          history.push('/')
                          keycloak.logout()
                        }, 2000)
                      })
                    }
                  }}
                /> */}
                <ReactTooltip effect="solid" id="twoFactorEnabled" />
              </Col>
            </Row>
            <WebAuthnCredentials handleEnrollDevice={handleEnrollWebAuthN} loading={enrollLoading} />
            <Row className="mb-3">
              {userData.hasPassword && (
                <>
                  <Col xs={12}>
                    <label className="text-primary  text-uppercase  font-weight-bold">Change Password</label>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} sm={6}>
                        <FormGroup>
                          <Label className="font-weight-bold">Current password</Label>
                          <Input
                            type="password"
                            disabled={!edit}
                            value={formValues.currentPassword}
                            bsSize="lg"
                            onChange={(e) => setFormValues({ ...formValues, currentPassword: e.target.value })}
                            autoComplete="false"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label className="font-weight-bold">New password</Label>
                  <Input
                    type="password"
                    disabled={!edit}
                    value={formValues.newPassword}
                    onChange={(e) => setFormValues({ ...formValues, newPassword: e.target.value })}
                    bsSize="lg"
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label className="font-weight-bold">Confirm password</Label>
                  <Input
                    type="password"
                    disabled={!edit}
                    value={formValues.confirmation}
                    onChange={(e) => setFormValues({ ...formValues, confirmation: e.target.value })}
                    bsSize="lg"
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col
                xs={12}
                sm={6}
                data-type="warning"
                data-place="bottom"
                data-border="true"
                data-multiline="true"
                data-tip="Enabling this option will remove all existing sessions."
                data-for="logoutActiveSession"
              >
                <StyledSwitch
                  type="switch"
                  id="logoutActiveSession"
                  name="logoutActiveSession"
                  label="Logout active sessions"
                  disabled={!edit}
                  innerRef={switchSession}
                  defaultChecked={true}
                  checked={formValues.logutActiveSession}
                  onClick={(e) => {
                    setFormValues({ ...formValues, logoutActiveSession: switchSession.current.checked })
                  }}
                />
                <ReactTooltip effect="solid" id="logoutActiveSession" />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} className="mb-3">
                <ErrorsList errors={errors} />
              </Col>
              <Col xs={12} className={`d-flex w-100 ${!edit ? 'justify-content-end' : 'justify-content-between'}`}>
                {!edit ? (
                  <a href="#!" className="btn btn-darker btn-dark-border" onClick={() => setEdit(true)}>
                    Edit
                  </a>
                ) : (
                  <>
                    <div className="align-items-center justify-between">
                      <Link to={ROUTE.DELETE_PROFILE} className="mr-3">
                        More options...
                      </Link>
                    </div>

                    <div className="d-flex align-items-center">
                      <a href="#!" className="mr-3" onClick={handleCancel}>
                        Cancel
                      </a>
                      <Button type="submit" color="darker" className="btn-dark-border" disabled={loading}>
                        Update
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Form>
          {isOpenOTPModal && (
            <OTPSetup
              open={isOpenOTPModal}
              toggle={() => {
                userStore.getUser()
                setEdit(false)
                setIsOpenOTPModal(!isOpenOTPModal)
                window.opener && window.opener.postMessage('2fa_verified', '*')
                windowClose()
              }}
              onCancel={() => {
                setFormValues({ ...formValues, twoFactorEnabled: false })
                setIsOpenOTPModal(!isOpenOTPModal)
                switchRef.current.checked = false
                switchSession.current.checked = false
              }}
              data={{ twoFactorEnabled: formValues.twoFactorEnabled }}
            />
          )}
          {isOpenWebAuthnModal && (
            <WebAuthnSetup
              open={isOpenWebAuthnModal}
              toggle={() => {
                userStore.getUser()
                // setEdit(false)
                setIsOpenWebAuthnModal(!isOpenWebAuthnModal)
                // window.opener && window.opener.postMessage('2fa_verified', '*')
                windowClose()
              }}
              // onCancel={() => {
              //   setFormValues({ ...formValues, twoFactorEnabled: false })
              //   setIsOpenWebAuthnModal(!isOpenWebAuthnModal)
              //   switchRef.current.checked = false
              //   switchSession.current.checked = false
              // }}
              // data={{ twoFactorEnabled: formValues.twoFactorEnabled }}
            />
          )}
        </Col>
      </Row>
    </MainLayout>
  )
}

export default inject('userStore')(observer(UserInfo))
