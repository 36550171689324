/* @flow */
import { decorate } from 'mobx'

import BaseStore from './base'

class DeviceStore extends BaseStore {
  constructor(service) {
    super()
    this.service = service
  }

}

export default decorate(DeviceStore, {})
