import React from 'react'
import Swal from 'sweetalert2'

import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'

import ErrorsList from 'Common/ErrorsList'
import MainLayout from 'Common/MainLayout'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-dark-border',
    cancelButton: 'btn btn-default',
  },
  buttonsStyling: false,
  allowOutsideClick: false,
})

const IC2Settings = ({ ic2SettingsStore }) => {
  const { data, loading, uploading = false, errors } = ic2SettingsStore.CRUD
  const [formValues, setFormValues] = React.useState({
    title: '',
    subtitle: '',
    readMoreUrl: '',
  })
  const inputFile = React.useRef(null)

  const getLoginSettings = async () => {
    await ic2SettingsStore.getById()
  }

  React.useEffect(() => {
    getLoginSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setFormValues({
      ...data,
    })
  }, [data])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { title, subtitle, readMoreUrl } = formValues
    await ic2SettingsStore.create(null, {
      title,
      subtitle,
      readMoreUrl,
    })
    if (!errors.length) {
      swalWithBootstrapButtons
        .fire({
          icon: 'success',
          title: 'Success',
          text: 'InControl 2 Display was successfully updated.',
          confirmButtonText: 'Close',
        })
        .then((res) => {
          getLoginSettings()
        })
    }
  }
  const handleField = (e) => {
    const { value, name } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleAttachmentChange = async (e) => {
    const values = e.target
    let formAttachment = new FormData()
    inputFile.current.classList.remove('is-invalid')
    // const reader = new FileReader()

    Object.keys(values.files).forEach((key) => {
      formAttachment.append('file', values.files[key])
    })

    await ic2SettingsStore.uploadAttachment(formAttachment)
    if (!errors.length) {
      getLoginSettings()

      swalWithBootstrapButtons
        .fire({
          icon: 'success',
          title: 'Success',
          text: 'Video was successfully uploaded.',
          confirmButtonText: 'Close',
        })
        .then((res) => {})
    }
  }

  return (
    <MainLayout>
      <Row className="pt-3 mt-3">
        <Col xs={12}>
          <h5 className=" text-center">IC2 Login Settings</h5>
        </Col>
      </Row>
      <Col xs={12} md={{ size: 6, offset: 3 }}>
        <Form onSubmit={handleSubmit} className="mt-2">
          <FormGroup>
            <Label>
              Title <sup>*</sup>
            </Label>
            <Input
              type="text"
              placeholder="Enter Title"
              name="title"
              defaultValue={formValues.title}
              onChange={handleField}
              requried=""
            />
          </FormGroup>

          <FormGroup>
            <Label>
              Subtitle <sup>*</sup>
            </Label>
            <Input
              type="text"
              placeholder="Enter Subtitle"
              name="subtitle"
              onChange={handleField}
              requried=""
              defaultValue={formValues.subtitle}
            />
          </FormGroup>

          <FormGroup>
            <Label>
              Learn More URL <sup>*</sup>
            </Label>
            <Input
              type="url"
              placeholder="Type Learn More UR"
              name="readMoreUrl"
              onChange={handleField}
              defaultValue={formValues.readMoreUrl}
              requried=""
            />
          </FormGroup>

          <FormGroup>
            <Label>
              Video File (.mv4a Format) <sup>*</sup>
            </Label>
            <Input
              type="file"
              name="attachment"
              requried=""
              onChange={handleAttachmentChange}
              hidden
              innerRef={inputFile}
              accept="video/mp4"
            />
            {formValues.attachment && formValues.attachment.resourcePath && (
              <div>
                <label>{formValues?.attachment?.fileName}</label>
                <video
                  src={`${process.env.REACT_APP_BASE_URL}${formValues.attachment.resourcePath}`}
                  height={250}
                  width="100%"
                  controls
                />
              </div>
            )}
            <div>
              <Button
                type="button"
                color="primary"
                className=""
                onClick={() => inputFile.current.click()}
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </Button>
            </div>
          </FormGroup>

          {/* {disabled && (
            <> */}
          <FormGroup>
            <ErrorsList errors={errors} />
          </FormGroup>
          <FormGroup className="text-right">
            <Button type="submit" color="darker" className="btn-dark-border" disabled={loading}>
              Update
            </Button>
          </FormGroup>
          {/* </>
          )} */}
        </Form>
      </Col>
    </MainLayout>
  )
}

export default inject('ic2SettingsStore')(observer(IC2Settings))
