import React from 'react'

import { inject, observer } from 'mobx-react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import FAQ from 'Components/FAQ'
import Home from 'Components/Home'
import IC2Settings from 'Components/Admin/IC2Settings'
import LoginHistory from 'Components/LoginHistory'
import LoginSummary from 'Components/Admin/LoginSummary'
import NewUserSummary from 'Components/Admin/NewUserSummary'
// import Organization from 'Components/Organization/Table'
import UserInfo from 'Components/UserInfo'

import { ROUTE } from 'configs'
import DeleteProfile from './DeleteProfile'

function Routes({ keycloakStore, userStore }) {
  const [keycloak, initialized] = useKeycloak()
  React.useEffect(() => {
    if (keycloak.token) {
      userStore.setToken({ id_token: keycloak.token })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token])
  const accessDenied = '/?error=access_denied'
  if (initialized) {
    if (keycloak)
      return (
        <Router>
          <Switch>
            <Route exact path={ROUTE.HOME} component={Home} />
            <Route exact path={ROUTE.PROFILE} component={UserInfo} />
            <Route exact path={ROUTE.LOGIN_HISTORY} component={LoginHistory} />
            <Route exact path={ROUTE.FAQs} component={FAQ} />
            <Route
              exact
              path={ROUTE.ADMIN_LOGIN_SUMMARY}
              render={() => (keycloakStore.isPeplinkAdmin() ? <LoginSummary /> : <Redirect to={accessDenied} />)}
            />
            <Route
              exact
              path={ROUTE.ADMIN_NEW_USER_SUMMARY}
              render={() => (keycloakStore.isPeplinkAdmin() ? <NewUserSummary /> : <Redirect to={accessDenied} />)}
            />
            <Route
              exact
              path={ROUTE.ADMIN_IC2_LOGIN_SETTINGS}
              render={() => (keycloakStore.isPeplinkAdmin() ? <IC2Settings /> : <Redirect to={accessDenied} />)}
            />
            <Route exact path={ROUTE.DELETE_PROFILE} component={DeleteProfile} />
            {/* <Route exact path="/organizations" component={Organization} /> */}
          </Switch>
        </Router>
      )
    return <div className="p-5">Authentication Failed, try again.</div>
  } else {
    return <div className="p-5">Please wait...</div>
  }
}

export default inject('keycloakStore', 'userStore')(observer(Routes))
