import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import React from 'react'
import useDebounce from 'hooks/useDebounce'

export default props => {
  const { columns, data, totalPages: pageCount, fetchData, filters, totalElements } = props
  const isServerSide = fetchData
  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        size: filters.size || 5,
        pageIndex: filters.pageIndex || 0,
        ...(filters.sortBy && { sortBy: filters.sortBy }),
      },
      ...(isServerSide && {
        pageCount,
        manualPagination: true,
        manualGlobalFilter: true,
        manualSortBy: true,
        disableSortRemove: true,
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    state: { pageIndex, size, globalFilter, sortBy },
    getTableProps,
    setGlobalFilter,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setsize,
  } = tableProps

  const rows = tableProps.page
  const isSearching = React.useRef(false)

  const [_size, _setsize] = React.useState(size)
  const [_pageIndex, _setPageIndex] = React.useState(pageIndex)
  const [_search, _setSearch] = React.useState()
  const [_sortBy, _setSortBy] = React.useState(sortBy)

  const debounced = useDebounce(value => setGlobalFilter(value))

  React.useEffect(() => {
    if (isSearching.current) {
      gotoPage(0)
    }
    _setPageIndex(isSearching.current ? 0 : pageIndex)
    _setsize(_size)
    _setSearch(globalFilter)
    _setSortBy(sortBy)
    isSearching.current = false
  }, [gotoPage, sortBy, pageIndex, globalFilter, _size])

  React.useEffect(() => {
    fetchData &&
      fetchData({
        size: _size,
        pageIndex: _pageIndex,
        ...(_search && { search: _search }),
        ...(_sortBy && { sortBy: _sortBy }),
      })
  }, [_pageIndex, _size, _search, _sortBy, fetchData])

  const handleSearch = value => {
    isSearching.current = true
    debounced(value)
  }

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setSize: isServerSide ? _setsize : setsize,
    rows,
    tableProps,
    onSearch: e => handleSearch(e.target.value),
    totalElements: isServerSide ? totalElements : data.length,
  }
}
