import NumberInput from '../NumberInput'
import React from 'react'
import styled from 'styled-components'
import useDebounce from 'hooks/useDebounce'

const Pager = function({
  state: { pageIndex, pageSize },
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  previousPage,
  onSetPageSize,
  totalElements,
}) {
  const [value, setValue] = React.useState('')
  const debounced = useDebounce(gotoPage)

  const handleChange = e => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0
    setValue(page)
    debounced(page)
  }

  React.useEffect(() => {
    setValue(pageIndex || 0)
  }, [pageIndex, setValue])

  return (
    <div className="table-footer d-flex align-items-center">
      <>
        <div className="rows-per-page mr-3">
          <select className="ml-2 form-control input-sm" onChange={e => onSetPageSize(Number(e.target.value))} defaultValue={pageSize} style={{width:50, background:'transparent'}}>
            {[5, 10, 15, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <p className="total-records mb-0">Total: {totalElements} Records</p>
        {pageCount > 1 && (
          <div className="ml-auto d-flex align-items-center">
            <PagerStyled className="pagination d-flex align-items-center m-0">
              <li className="page-item">
                <button
                  className="page-link material-icons md-dark md-24"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  chevron_left
                </button>
              </li>
              <li>
                <form className="d-flex align-items-center m-2">
                  <NumberInput value={value + 1} onChange={handleChange} onClick={e => e.target.select()} /> of{' '}
                  {pageCount}
                </form>
              </li>

              <li className="page-item">
                <button
                  className="page-link material-icons md-dark md-24"
                  onClick={nextPage}
                  disabled={!canNextPage || value + 1 >= pageCount}
                >
                  chevron_right
                </button>
              </li>
            </PagerStyled>
          </div>
        )}
      </>
    </div>
  )
}

export default Pager

const PagerStyled = styled.ul`
  button:disabled {
    opacity: 0.2;
  }
  form input {
    transition: all 0.3s ease-out;
    border: 1px solid #a9a9a9;
    padding: 0;
    width: 30px;
    &:focus {
      width: 50px;
    }
  }
`
