import qs from 'query-string'
import React, { useEffect } from 'react'

import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { KeycloakProvider } from '@react-keycloak/web'
import { toast, ToastContainer } from 'react-toastify'

import FAQ from 'Components/FAQ'
import Routes from './Components/Routes'

import './scss/peplink-sso.scss'
import 'react-toastify/dist/ReactToastify.css'

function App({ keycloakStore, userStore }) {
  useEffect(() => {
    const { passwordNotifChange = null } = qs.parse(window.location.search)
    if (passwordNotifChange && passwordNotifChange.toString() === 'true') {
      toast.success('Your password has been updated.')
    }
  }, [])

  return (
    <AppRouter>
      <Switch>
        <Route exact path="/faq" render={(props) => <FAQ {...props} noUser />} />
        <Route
          render={() => {
            return (
              <KeycloakProvider
                keycloak={keycloakStore.keycloak}
                initConfig={{
                  onLoad: 'login-required',
                }}
                onTokens={(tokens) => {
                  if (tokens && tokens.token) {
                    userStore.setToken({ id_token: tokens.token })
                  }
                }}
              >
                <Routes />
              </KeycloakProvider>
            )
          }}
        />
      </Switch>
      <ToastContainer position="bottom-right" theme="dark" />
    </AppRouter>
  )
}

export default inject('keycloakStore', 'userStore')(observer(App))
