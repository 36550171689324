import React, { useState } from 'react'
import { Collapse, CardBody, Card, CardTitle, CardText } from 'reactstrap'

const Collapsible = ({ id, key, title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Card className="mb-0" color="transparent" key={key+id}>
      <CardTitle
        id={id}
        className="border-bottom border-primary cursor-pointer font-weight-bold p-2 d-flex justify-content-between mb-0 align-items-center"
        onClick={toggle}
      >
        <span>{title}</span>
        <span className="text-primary font-weight-light">
          {isOpen ? (
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-chevron-up"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              />
            </svg>
          ) : (
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-chevron-down"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          )}
        </span>
      </CardTitle>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <CardText>{content}</CardText>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default Collapsible
