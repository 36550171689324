import { Button, Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import React from 'react'
import ReactTooltip from 'react-tooltip'

import { getFormattedDateTimeString } from 'utils/formatters'
import Loader from 'Common/Loader'
import ReactTable from 'Common/ReactTable'

const WebAuthnCredentials = ({ userStore, handleEnrollDevice, loading: enrollLoading }) => {
  const [loading, setLoading] = React.useState()
  const [reactTableData, setReactTableData] = React.useState([])
  const [totalElements, setTotalElements] = React.useState()
  const [filters] = React.useState({
    size: 10,
    pageIndex: 0,
    sortBy: [
      {
        id: 'timestamp',
        desc: true,
      },
    ],
  })

  const handleDelete = async (credentialId) => {
    if (window.confirm('Are you sure you want to remove this device?')) {
      await userStore.deleteWebAuthN(credentialId)
      getUser()
    }
  }

  const columns = [
    {
      Header: 'Date/Time',
      accessor: 'createdDate',
      style: { width: '15%' },
      Cell: ({
        cell: {
          row: { original },
        },
      }) => getFormattedDateTimeString(original.createdDate),
    },
    {
      Header: 'Label',
      accessor: 'userLabel',
      style: { width: '15%' },
    },
    {
      Header: 'Action',
      style: { width: '15%', textAlign: 'center' },
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        // eslint-disable-next-line no-script-url
        <a href="javascript:void(0);" className="text-dark" onClick={() => handleDelete(original.id)}>
          <i className="material-icons mr-2">delete</i>
        </a>
      ),
    },
  ]

  const getUser = async () => {
    const { data } = await userStore.getWebAuthnPasswordless()
    setTotalElements(data.length)
    setReactTableData(data)
    setLoading(false)
  }

  React.useEffect(() => {
    setLoading(true)
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className="mb-3">
      <Col xs={12}>
        <label className="p-2 mb-2 text-primary text-uppercase font-weight-bold">
          Login With Passkeys
          <div
            className="position-absolute font-weight-normal"
            style={{ right: 24, top: 0 }}
            data-type="warning"
            data-place="bottom"
            data-border="true"
            data-multiline="true"
            data-for="enrollDevice"
            // eslint-disable-next-line max-len
            data-tip="Generate a passkey in this device for passwordless login."
          >
            <Button color="primary" size="sm" onClick={handleEnrollDevice} disabled={enrollLoading || loading}>
              {enrollLoading ? 'Enrolling Device...' : 'Enroll Device'}
            </Button>
            <ReactTooltip effect="solid" id="enrollDevice" />
          </div>
        </label>
      </Col>
      <Col>
        <ReactTable
          columns={columns}
          data={reactTableData}
          options={{
            filters,
            fetchData: null,
            totalElements: totalElements,
            totalPages: 1,
          }}
          search={false}
          paginate={false}
        />
        {loading && (
          <div className="loader-container">
            <Loader />
          </div>
        )}
      </Col>
    </Row>
  )
}

export default inject('userStore')(observer(WebAuthnCredentials))
