import BaseService from './base'

class IC2SettingsService extends BaseService {
  constructor(api) {
    super()
    this.api = api
    this.url = `/ic2/login/editableFields`
    this.attachmentUrl = '/attachments/video'
  }

  uploadAttachment = async (attachment) => {
    return await this.api.post(this.attachmentUrl, attachment, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}

export default IC2SettingsService
