/* @flow */
import BaseService from './base'

class DeviceService extends BaseService {
  constructor(api) {
    super()
    this.api = api
    this.url = `/organizations/`
  }

}

export default DeviceService
