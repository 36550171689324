/* eslint-disable max-len */
import { action, decorate } from 'mobx'

class MarkerIOStore {
  constructor(key, env, allowedEnvs = ['development', 'staging', 'production']) {
    this.init({ key, env, allowedEnvs })
  }

  init({ key, env, allowedEnvs }) {
    if (allowedEnvs.includes(env)) {
      ;(function () {
        const markerConfig = document.createElement('script')
        const markerScript = document.createElement('script')
        markerConfig.type = 'text/javascript'
        markerConfig.text = `
          window.markerConfig = {
            destination: '${key}',
            source: 'snippet'
          };
        `
        const x = document.getElementsByTagName('head')[0]
        x.appendChild(markerConfig)

        markerScript.type = 'text/javascript'
        markerScript.text = `
          !function(e,r,a){if(!e.Marker){e.Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
        `
        const y = document.getElementsByTagName('head')[0]
        y.appendChild(markerScript)
      })()
    }
  }
}

export default decorate(MarkerIOStore, {
  init: action,
})
