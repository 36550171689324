import { inject, observer } from 'mobx-react'
import { Button, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Swal from 'sweetalert2'

import { ROUTE } from 'configs'
import MainLayout from 'Common/MainLayout'
import { useKeycloak } from '@react-keycloak/web'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    cancelButton: 'btn btn-default',
  },
  buttonsStyling: true,
  allowOutsideClick: false,
})

const DeleteProfile = ({ userStore }) => {
  const [keycloak, initialized] = useKeycloak()
  const [user, setUser] = useState({})

  const handleOpenDeletePopup = () => {
    swalWithBootstrapButtons
      .fire({
        title: 'Do you confirm to permanently delete your Peplink ID?',
        text: 'The action cannot be undone',
        confirmButtonText: 'DELETE',
        confirmButtonColor: '#f44336',
        cancelButtonColor: '#dd',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          const { data } = await userStore.deleteProfile(user)
          if (data.success) window.location.href = window.location.origin
          else
            swalWithBootstrapButtons
              .fire({
                icon: 'error',
                title: 'Failed',
                text: 'Something went wrong. Please try again.',
                confirmButtonText: 'Close',
              })
              .then((res) => {})
        }
      })
  }
  React.useEffect(() => {
    if (initialized && !!keycloak.token) {
      keycloak.loadUserInfo().then((userInfo) => {
        setUser({ ...userInfo })
      })
    }
  }, [keycloak, initialized])

  return (
    <MainLayout hasHeader={false} noNavBar>
      <Row>
        <div className="mt-5 w-100">
          <h6 className="text-center">More Options</h6>

          <div className="text-center mt-3">
            <Button type="submit" color="darker" className="btn-dark-border" onClick={handleOpenDeletePopup}>
              Permanently delete Peplink ID
            </Button>
          </div>
          <div className="text-center mt-3">
            <Link to={ROUTE.PROFILE} className="small">
              Return to Profile
            </Link>
          </div>
        </div>
      </Row>
    </MainLayout>
  )
}

export default inject('userStore')(observer(DeleteProfile))
