import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Form, Row, Col, Input } from 'reactstrap'
import styled from 'styled-components'
import Swal from 'sweetalert2'

import ErrorsList from 'Common/ErrorsList'

const StyledOl = styled.ol`
  margin: 0;
  padding: 5px 5px 0;
  li {
    margin: 0;
    margin-bottom: 15px;
    font-size:0.75em;
    ul li {
     font-size:1em;
    }
  }
`
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-dark-border',
    cancelButton: 'btn btn-default',
  },
  buttonsStyling: false,
  allowOutsideClick: false,
})

const OTPSetup = ({ userStore, open, toggle, onCancel, data }) => {
  const [formValues, setFormValues] = React.useState({})
  const [qrCode, setQrCode] = React.useState(null)
  const [secretKey, setSecretKey] = React.useState(null)
  const handleSubmit = async(e) => {
    e.preventDefault()
    const res = await userStore.submitQRSetup({...formValues, ...data})
    if(res) {
        swalWithBootstrapButtons.fire({
            icon: 'success',
            title: 'Success',
            text: 'Two Factor Authentication has been enabled!',
            confirmButtonText: 'Close',
        })
        toggle()
    } 
  }
  React.useEffect(() => {
    userStore.getQR().then(({ data }) => {
      setQrCode(data.qrCode)
      setSecretKey(data.secretKey)
    })
  }, [userStore])
  const {loading, errors} = userStore.OTPSetup
  return (
    <Modal isOpen={open} size="md" backdrop="static">
      <Form onSubmit={handleSubmit}>
        <ModalHeader style={{ background: '#f3f1ed' }}>
          <h6 className="mb-0">OTP Setup</h6>
        </ModalHeader>
        <ModalBody style={{ background: '#f3f1ed' }}>
          <Col xs={12}>
            <StyledOl>
              <li>
                Install one of the following applications on your mobile
                <ul>
                  <li className="mb-0">Google Authenticator</li>
                  <li className="mb-0">FreeOTP</li>
                </ul>
              </li>
              <li>
                <div className="text-center">
                  <div className="text-left">Using your two-factor app, <b>scan the QR code</b> <i>OR</i> manually <b>enter the secret key</b> to add your Peplink ID account to the app.</div>
                  {qrCode && (
                    <img
                      id="kc-totp-secret-qr-code"
                      className="mt-3"
                      style={{ marginleft: 'auto', marginRight: 'auto', height: 200, width: 200 }}
                      src={`data:image/png;base64, ${qrCode}`}
                      alt="Figure: QR Code"
                    />
                  )}
                  {secretKey && (
                    <div className="text-left">
                      <br/>
                      <span><b>Secret key :</b> <b style={{color: "green"}}>{secretKey}</b></span>
                    </div>
                  )}
                </div>
              </li>
              <li>
                Enter the one-time code provided by the application and click Submit to finish the setup <br /> <br />
                Provide a Device Name to help you manage your OTP devices.
              </li>
            </StyledOl>
            <Row className="mt-2">
              <Col xs={12}>
                <ErrorsList errors={errors} />
                <FormGroup>
                  <Label className="font-weight-bold">
                    One-time Code <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    required
                    onChange={(e) => setFormValues({ ...formValues, otp: e.target.value })}
                    bsSize="lg"
                    disabled={loading}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter style={{ background: '#f3f1ed' }}>
          <a href="#!" className="mr-3" onClick={onCancel} disabled={loading}>
            Cancel
          </a>
          <Button type="submit" color="darker" className="btn-dark-border" disabled={loading}>
            {loading ? 'Please wait...' : 'Submit'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default inject('userStore')(observer(OTPSetup))
