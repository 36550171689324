import React from 'react'
import { inject, observer } from 'mobx-react'
import { useKeycloak } from '@react-keycloak/web'
import { Navbar, Container } from 'reactstrap'
import styled from 'styled-components'

import AccountDropDown from 'Components/AccountDropDown'
import Logo from 'scss/assets/images/peplink-light.svg'
import { Link } from 'react-router-dom'

const MainLayout = ({
  userStore,
  keycloakStore,
  children,
  title = null,
  subtitle = null,
  hasHeader = true,
  noUser = false,
  noNavBar = false,
}) => {
  const [keycloak, initialized] = useKeycloak()
  React.useEffect(() => {
    if (initialized && !!keycloak.token) {
      keycloak.loadUserInfo().then((userInfo) => {
        userStore.setDetailsFromKeycloak(userInfo)
      })
    }
  }, [keycloak, initialized, userStore])
  return (
    <>
      {/* <Header userDetails={userStore.CRUD.data} title={title} subtitle={subtitle} hasHeader={hasHeader}/> */}
      {!noNavBar && (
        <Navbar color="transparent" light expand="xs" {...(noUser && { className: 'd-flex justify-content-center' })}>
          {!noUser && <AccountDropDown keycloak={keycloak} userDetails={userStore.CRUD.data} />}
        </Navbar>
      )}

      {noUser && (
        <StyledSection className="small">
          <Link className="text-darker" to="/">
            Sign in
          </Link>
          <Link className="text-darker" to="/faq">
            FAQ
          </Link>
          <a
            className="btn btn-sm btn-dark p-2"
            href={`${process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/registrations?client_id=${process.env.REACT_APP_KEYCLOAK_RESOURCE}&response_type=code&scope=openid%20email&redirect_uri=${process.env.REACT_APP_BASE_URL}&kc_locale=en`}
            rel="noopener noreferrer"
          >
            CREATE A PEPLINK ID
          </a>
        </StyledSection>
      )}
      <Container>
        <div className="logo d-flex justify-content-center mt-1">
          <img src={Logo} alt="Peplink" className="mt-4" width={245} height={89} />
        </div>
        {children}
      </Container>
    </>
  )
}

const StyledSection = styled.section`
  position: absolute;
  right: 0;
  top: 0;
  a {
    padding: 10px;
    margin-left: 3.5px;
    &.text-darker {
      color: #333;
    }
    &:hover {
      color: #ffb81c;
    }
  }
  .btn-dark {
    background: #000;
    border: none;
    border-radius: 0;
    font-size: 8pt;
    font-weight: 600;
    color: #ffb81c;
    &:hover {
      color: #000;
      text-decoration: none;
      background: #ffb81c;
    }
  }
`

export default inject('userStore', 'keycloakStore')(observer(MainLayout))
