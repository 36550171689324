export default class BaseService {
  constructor(api, url = '') {
    this.api = api
    this.url = url
  }
  get = (url, payload) => {
    return this.api.get(`${this.url}${url || ''}`, payload)
  }
  getById = id => {
    return this.api.get(`${this.url}${id}`)
  }
  fetchTable = payload => {
    return this.api.get(this.url, payload)
  }
  fetchList = id => {
    return this.api.get(`${this.url}${id}`)
  }
  create = (url, payload) => {
    return this.post(url, payload)
  }
  post = (url, payload) => {
    return this.api.post(`${this.url}${url || ''}`, payload)
  }
  put = (url, payload) => {
    return this.api.put(`${this.url}${url || ''}`, payload)
  }
  patch = (url, payload) => {
    return this.api.patch(`${this.url}${url || ''}`, payload)
  }
  delete = url => {
    return this.api.delete(`${this.url}${url || ''}`)
  }
  setToken = data => {
    this.api.setToken(data)
  }
  getToken = () => {
    return this.api.getToken()
  }
  removeToken = () => {
    return this.api.removeToken()
  }
}
