import Pager from './Pager'
import React from 'react'
import useReactTable from './useReactTable'

export default (params) => {
  const { columns, data, className, search = true, paginate = true, ...rest } = params
  const defaultOptions = {
    totalElements: 0,
    showPager: paginate,
    onRowClick: null,
    ...rest.options,
  }

  const {
    tableProps,
    totalElements,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    rows,
    setSize,
    onSearch,
  } = useReactTable({
    columns,
    data,
    defaultColumn: React.useMemo(
      () => ({
        sortable: true,
        width: 20,
        Cell: ({ cell: { value } }) => (typeof value !== 'undefined' && value !== null ? String(value) : null),
      }),
      []
    ),
    ...defaultOptions,
  })

  return (
    <div className={className}>
      {search && <input type="text" className="form-control search mb-1" placeholder="Search" onChange={onSearch} />}
      <table {...getTableProps()} className="table table-striped table-hover bg-transparent small">
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr key={`tr-head-${key}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, key) => (
                <th
                  key={`th-${key}`}
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                  ])}
                  onClick={() => column.accessor && column.canSort && column.toggleSortBy()}
                  className="border border-right-0 border-left-0 border-primary pt-3 pb-3 text-black-50"
                >
                  {column.render('Header')}
                  {column.sortable && (
                    <span className="material-icons md-18">
                      {column.isSorted ? (column.isSortedDesc ? 'arrow_drop_down' : 'arrow_drop_up') : ''}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, key) => {
            prepareRow(row)
            return (
              <tr
                key={`tr-body-${key}`}
                {...row.getRowProps()}
                onClick={(e) => {
                  if (!e.target.closest('.action')) {
                    defaultOptions.onRowClick && defaultOptions.onRowClick(row)
                  }
                }}
              >
                {row.cells.map((cell, key) => {
                  return (
                    <td
                      key={`td-${key}`}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {!rows.length && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                No Record Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {defaultOptions.showPager && (
        <Pager
          {...tableProps}
          totalElements={totalElements}
          onSetPageSize={(pageSize) => {
            gotoPage(1)
            setSize(pageSize)
          }}
        />
      )}
    </div>
  )
}
